import { useEffect, useState } from "react";
import { getLoggedInUserInfo } from "../api/axiosClient";
import { useHistory, Redirect } from "react-router-dom";
import authApi from "../api/authApi";


const AuthProtected = (props: any) => {

  const history = useHistory();

  const [userProfile] = useState<any>(getLoggedInUserInfo())
  const [loading] = useState<boolean>(userProfile ? false : true);

  /*
    Navigate is un-auth access protected routes via url
    */
  const currentUrl = window.location.pathname + window.location.search;// Get the current URL

  if (!userProfile && loading) {
    return <Redirect to="/login" />;
  }


  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const resProfile = async () => {
      try {
        const [resProfile]: any = await Promise.all([authApi?.profile()]);
        if (resProfile?.data) {
          const finalLogin = {
            ...userProfile,
            user: {
              ...userProfile?.user,
              ...resProfile?.data,
            }
          };
          localStorage.setItem("authUser", JSON.stringify(finalLogin));
        }
      } catch (error: any) {

        return error;
      }
    };
    resProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{props.children}</>;
};


export default AuthProtected;