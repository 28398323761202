import { LoadingOutlined } from '@ant-design/icons';
import { Col, Row } from "antd";
import { useState } from "react";
import { Slide } from "react-awesome-reveal";
import { TFunction, withTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import authApi from "../../api/authApi";
import { ImgIcon } from "../../common/SvgIcon";
import { ContentWrapper, GenerateImageBlockSection, LogoContainer } from "./styles";


interface GenerateImageBlockProps {
  t: TFunction;
}



const LoginForm = ({ t }: GenerateImageBlockProps) => {

  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState<string>('');

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const [errorEmail, setErrorEmail] = useState<string>('');

  const [errorSubmit, setErrorSubmit] = useState<string>('');

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!email || !password || !!errorEmail) {
      return;
    }
    setErrorSubmit((_prev) => '');
    try {
      setLoading((_prev) => true);
      let response = await authApi.login({
        email: email,
        password: password,
        device_id: '',
        // provider_type: 'default'
      });
      setLoading((_prev) => false);
      if (response) {
        const TYPE_LOGIN = localStorage.getItem("TYPE_LOGIN");
        let finallogin: any = response?.data;
        const referralUrl = localStorage.getItem('referralUrl') || '/';
        localStorage.removeItem('referralUrl');
        localStorage.setItem("authUser", JSON.stringify(finallogin));
        history.push(referralUrl);
      }
    } catch (error) {
      setErrorSubmit((_prev) => `${error || ''}`);
      setLoading((_prev) => false);
    }
  }

  const validateEmail = (email: string) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return regex.test(email);
  };

  return (
    <GenerateImageBlockSection>
      <Slide direction="up" triggerOnce style={{ width: '100%' }}>
        <Row justify="center" align="middle">
          <ContentWrapper>
            <form onSubmit={handleSubmit}>
              <Row gutter={20}>

                <Col lg={24} md={24} sm={24} xs={24}>
                  {/* <h6>{t('Welcome back')}</h6> */}
                  <LogoContainer to="/" aria-label="homepage">
                    <ImgIcon src="logo.png" width="192px" height="22px" style={{ margin: '5px 0px' }} />
                  </LogoContainer>
                  <p style={{ fontSize: '15px', textAlign: 'center', marginBottom: '30px' }}>
                    {t('Please log in to continue using the system.')}
                  </p>
                </Col>
                <Col lg={7} md={5} sm={4} xs={0}></Col>
                <Col lg={10} md={14} sm={16} xs={24}>
                  <div className="div-input-search">
                    <label>{t('Email')} <span style={{ color: 'red' }}>*</span></label>
                    <input
                      placeholder={`${t('Email')}...`}
                      name="email"
                      className="input-search"
                      value={email}
                      onChange={(e) => {
                        setEmail((_prev) => e?.target?.value || '');
                        setErrorEmail(validateEmail(e?.target?.value) ? '' : 'Invalid email address');
                      }}
                    ></input>
                    {!!errorEmail && <p className="text-error">{t(errorEmail || '')}</p>}
                  </div>
                  <div className="div-input-search">
                    <label>{t('Password')} <span style={{ color: 'red' }}>*</span></label>
                    <input
                      placeholder={`${t('Password')}...`}
                      name="password"
                      className="input-search"
                      value={password}
                      type="password"
                      style={{ fontFamily: 'sans-serif' }}
                      onChange={(e) => {
                        setPassword((_prev) => e?.target?.value || '');
                      }}
                    ></input>
                  </div>
                  <div>
                    {!!errorSubmit && <p className="text-error">{t(errorSubmit || '')}</p>}
                  </div>
                  <div>
                    <button type="submit" className={`btn-submit`}>
                      {loading ? <LoadingOutlined /> : t('Login')}
                    </button>
                  </div>
                </Col>
                <Col lg={7} md={5} sm={4} xs={0}></Col>
              </Row>
            </form>
          </ContentWrapper>
        </Row>
      </Slide >
    </GenerateImageBlockSection >
  );
};

export default withTranslation()(LoginForm);
